<template>
<div class="prose">
    <h1>Arity Solutions</h1>
    <p>coming soon!</p>
</div>
</template>

<script>
export default {
    name: 'Arity Solutions',
    props: {
        msg: String
    }
}
</script>
